import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../../store';
import { BasicSelect, ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
import dropdown from '../../../../shared/dropdown';
import { format } from 'date-fns';
// import URL_UTILITY from '@/app/utility/url.utility';
export default {
  name: 'ItcDataUpdation',
  components: {
    BasicSelect,
    ModelSelect,
    DatePicker,
    dropdown
  },
  watch: {
    selectedLegalEntity: function() {
      this.parent_value_set_id = this.selectedLegalEntity.value;
    },
    currentPage: function() {
      this.getItcDataUpdation();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getItcDataUpdation();
    }
  },
  props: ['searchRequestParams', 'updateNearMatchData'],
  data() {
    return {
      show_all_flag: true,
      reco_flag: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      correction_flag: false,
      showExcelUploadModal: false,
      payload: null,
      unsubscribe: null,
      totalRows: null,
      currentPage: 1,
      showValueSetModal: false,
      setTimeVsetCode: null,
      vsetCode: null,
      valueSetName: null,
      parent_value_set_id: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      // invoiceFromToDate: [],
      data: [],
      correctInvoiceNumber: null,
      defaultValue: {
        value: null,
        text: null
      },
      selectedLegalEntity: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      invTypeLookup: {
        value: null,
        text: null
      },
      selectedGSTNumber: {
        value: null,
        text: null
      },
      source: {
        value: null,
        text: null
      },
      invoiceNumber: null,
      periodType: null,
      itcDataUpdationData: [],
      GSTNumberTypeList: [],
      legalEntityList: [],
      itcDataUpdationFields: [
        {
          key: 'ou_name',
          class: 'text-center-margin'
        },
        {
          key: 'party_name',
          class: 'text-center-margin'
        },
        {
          key: 'invoice_type',
          class: 'text-center'
        },
        {
          key: 'invoice_number',
          class: 'text-center'
        },
        {
          key: 'invoice_date',
          class: 'text-center'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'text-center'
        },
        {
          key: 'first_party_gst',
          label: 'First Party GST',
          class: 'text-center'
        },
        {
          key: 'third_party_gst',
          label: 'Third Party GST',
          class: 'text-center'
        },
        {
          key: 'invoice_amount',
          class: 'text-center'
        },
        {
          key: 'taxable_amount',
          class: 'text-center'
        },
        {
          key: 'tax_rate',
          class: 'text-center'
        },
        {
          key: 'period_name',
          class: 'text-center'
        },
        {
          key: 'status',
          class: 'd-none text-center'
        },
        {
          key: 'correction_flag',
          label: 'Correction Flag',
          class: 'text-center'
        },
        {
          key: 'reco_status_2b',
          label: 'Reco Status',
          class: 'text-center'
        },
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'correct_invoice_number',
          label: 'Correct Invoice Number',
          class: 'text-center'
        },
        {
          key: 'correct_invoice_date',
          class: 'text-center'
        },
        {
          key: 'correct_first_party_gst',
          label: 'Correct First Party GST',
          class: 'text-center'
        },
        {
          key: 'correct_third_party_gst',
          label: 'Correct Third Party GST',
          class: 'text-center'
        },
        {
          key: 'remarks',
          class: 'text-center'
        }
      ],
      thirdPartyGst: null,
      startDate: null,
      endDate: null,
      selectedRow: [],
      selectAllCheckBox: false,
      docTypeList: ['Tax Rate Wise', 'Doc Wise'],
      selectedDocType: 'Tax Rate Wise',
      indexOfTaxRate: null,
      correMadeOn: format(new Date(), 'dd-MMM-yyyy')
    };
  },
  validations: {
    selectedLegalEntity: {
      value: {
        required
      }
    },
    // ouName: {
    //   value: {
    //     required
    //   }
    // },
    startDate: {
      required
    },
    endDate: {
      required
    },
    selectedDocType: {
      required
    }
  },
  mounted() {
    // this.abcd();
    this.getLegalEntity();
    if (this.searchRequestParams) {
      this.getParentToChildData(this.searchRequestParams);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'itcDataUpdation/getItcDataUpdation',
            'itc-Data-Updation',
            () => (this.loader = false)
          );
        }
        if (actionName === 'upload') {
          this.showExcelUploadModal = true;
        }
        if (actionName === 'save') {
          this.addEditItcDataUpdation();
        }
      }
    });
    if (this.updateNearMatchData && this.$route.name === 'getNearMatchForm') {
      this.selectedLegalEntity = {
        value: this.updateNearMatchData.legalEntity.value,
        text: this.updateNearMatchData.legalEntity.text
      };
      this.selectedGSTNumber = {
        value: this.updateNearMatchData.gstNumber.value,
        text: this.updateNearMatchData.gstNumber.text
      };
      this.startDate = commonHelper.getFirstDateOfMonth(
        this.updateNearMatchData.periodFrom.text
      );
      this.endDate = commonHelper.getLastDateOfMonth(
        this.updateNearMatchData.periodTo.text
      );
      this.correction_flag = false;
      this.reco_flag = false;
      this.show_all_flag = true;
      this.selectedDocType = 'Doc Wise';
      this.getItcDataUpdation();
    }
  },
  methods: {
    showFlag(value) {
      if (value === 'recoFlag' || value === 'correctionFlag') {
        this.show_all_flag = false;
      }
      if (value === 'showAll') {
        this.reco_flag = false;
        this.correction_flag = false;
      }
    },
    selectBoxChecked(flag, index, item, invId) {
      this.itcDataUpdationData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.invoice_id !== invId
        );
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.itcDataUpdationData = this.itcDataUpdationData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
        this.selectedRow = this.itcDataUpdationData;
      } else {
        this.itcDataUpdationData = this.itcDataUpdationData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
      }
    },
    getItcDataUpdation() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.selectedLegalEntity.value,
          ou_name: this.ouName.value,
          invoice_type_lookup: this.invTypeLookup.value,
          first_party_gst: this.selectedGSTNumber.value,
          source: this.source.value,
          // invoice_date_from: commonHelper.formattedDate(this.invoiceFromToDate[0]),
          // invoice_date_to: commonHelper.formattedDate(this.invoiceFromToDate[1]),
          invoice_date_from: this.startDate,
          invoice_date_to: this.endDate,
          invoice_number: this.invoiceNumber,
          correct_invoice_number: this.correctInvoiceNumber,
          reco_flag: this.reco_flag,
          correction_flag: this.correction_flag,
          show_all_flag: this.show_all_flag,
          third_party_gst: this.thirdPartyGst,
          type_wise:
            this.selectedDocType === 'Tax Rate Wise' ? 'rate_wise' : 'doc_wise',
          correction_made_on:
            this.$route.name === 'getNearMatchForm' ? this.correMadeOn : null
        };
        this.loader = true;
        this.$store
          .dispatch('itcDataUpdation/getItcDataUpdation', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.itcDataUpdationData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    addEditItcDataUpdation() {
      const filterData = this.itcDataUpdationData.filter(
        data => data.selectBox && data.reco_status_2b === 'Unreconcilled'
      );
      if (filterData.length) {
        const itemDetails = filterData.map(elem => {
          return {
            invoice_id: elem.invoice_id,
            correct_invoice_date: elem.correct_invoice_date
              ? elem.correct_invoice_date
              : elem.invoice_date,
            correct_invoice_number: elem.correct_invoice_number
              ? elem.correct_invoice_number
              : elem.invoiceNumber,
            correct_third_party_gst: elem.correct_third_party_gst
              ? elem.correct_third_party_gst
              : elem.third_party_gst,
            correct_first_party_gst: elem.correct_first_party_gst
              ? elem.correct_first_party_gst
              : elem.first_party_gst,
            remarks: elem.remarks ? elem.remarks : null
          };
        });
        const payload = {
          page: itemDetails ? itemDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('itcDataUpdation/addEditItcDataUpdation', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.itcDataUpdationData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
              this.$bvToast.toast(resp.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
              this.getItcDataUpdation();
              // this.showAlert = true;
              // this.isSuccess = true;
              // this.respMessage = resp.data.message;
            } else {
              // this.showAlert = true;
              // this.isSuccess = false;
              // this.respMessage = resp.response.data.message;
              this.$bvToast.toast(resp.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        this.$bvToast.toast('Please select atleast one record', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    getLegalEntity() {
      const payload = store.state.shared.responsibilityId;
      this.$store
        .dispatch('receipt/getOrganizationLov', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.org_name
              };
            });
            this.legalEntityList = leagalEntity;
            this.selectedLegalEntity =
              leagalEntity && leagalEntity.length === 1
                ? leagalEntity[0]
                : this.selectedLegalEntity;
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.ouName = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GST_OPERATING_UNIT) {
        this.ouName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_INVOICE_LOOKUP_TYPE
      ) {
        this.invTypeLookup = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.selectedGSTNumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
      // if (this.vsetCode === appStrings.VALUESETTYPE.THIRD_FIRST_PARTY_GSTN) {
      //   this.thirdPartyGst = {
      //     value: item.value_set_dtl_id,
      //     text: item.value_code
      //   };
      // }
      else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE) {
        this.source = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode, periodType) {
      this.vsetCode = vsetCode;
      this.periodType = periodType;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.GST_OPERATING_UNIT) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      this.selectedLegalEntity = this.defaultValue;
      this.ouName = this.defaultValue;
      this.invTypeLookup = this.defaultValue;
      this.selectedGSTNumber = this.defaultValue;
      this.source = this.defaultValue;
      this.invoiceNumber = null;
      this.correctInvoiceNumber = null;
      // this.invoiceFromToDate = [];
      this.startDate = null;
      this.endDate = null;
      this.itcDataUpdationData = [];
      this.reco_flag = null;
      this.correction_flag = null;
      this.thirdPartyGst = null;
    },
    rowSelected() {},
    clearVsetValues(vsetCode, index) {
      if (vsetCode === this.selectedLegalEntity.value) {
        this.selectedLegalEntity = this.defaultValue;
      } else if (vsetCode === this.ouName.value) {
        this.ouName = this.defaultValue;
      } else if (vsetCode === this.invTypeLookup.value) {
        this.invTypeLookup = this.defaultValue;
      } else if (vsetCode === this.selectedGSTNumber.value) {
        this.selectedGSTNumber = this.defaultValue;
      } else if (vsetCode === this.source.value) {
        this.source = this.defaultValue;
      } else if (vsetCode === this.thirdPartyGst) {
        this.thirdPartyGst = null;
      } else if (vsetCode === this.invoiceNumber) {
        this.invoiceNumber = null;
      } else if (vsetCode === this.correctInvoiceNumber) {
        this.correctInvoiceNumber = null;
      } else if (vsetCode === 'corrInvNumGrid') {
        this.itcDataUpdationData[index].correct_invoice_number = null;
      } else if (vsetCode === 'corrFirstPartyGstGrid') {
        this.itcDataUpdationData[index].correct_first_party_gst = null;
      } else if (vsetCode === 'corrThirdPartyGstGrid') {
        this.itcDataUpdationData[index].correct_third_party_gst = null;
      } else if (vsetCode === 'remarksGrid') {
        this.itcDataUpdationData[index].remarks = null;
      }
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    getParentToChildData(params) {
      this.selectedLegalEntity = {
        value: params.leName.value,
        text: params.leName.text
      };
      this.invoiceNumber = params.rowData.invoice_num;
      this.startDate = params.rowData.invoice_date;
      this.endDate = params.rowData.invoice_date;
      this.getItcDataUpdation();
    },
    changeDocType(key) {
      if (key === 'Doc Wise') {
        this.indexOfTaxRate = this.itcDataUpdationFields.findIndex(
          field => field.key === 'tax_rate'
        );
        this.itcDataUpdationFields.splice(this.indexOfTaxRate, 1);
      } else if (key === 'Tax Rate Wise') {
        const docTypeField = {
          key: 'tax_rate',
          class: 'text-center'
        };
        this.itcDataUpdationFields.splice(this.indexOfTaxRate, 0, docTypeField);
        this.indexOfTaxRate = null;
      }
      if (this.selectedLegalEntity.value && this.startDate && this.endDate) {
        this.getItcDataUpdation();
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
